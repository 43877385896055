import { reactive, toRefs } from "vue";
import axiosInstance from "axios";
import applyCaseMiddleware from "axios-case-converter";
import { BASE_URL } from "@/constants/env";
import { useAuthentication } from "@/composables/useAuthentication";
import { ROUTE_PAGE_NOT_FOUND } from "@/constants/routes";
import router from "@/router";

const axiosData = reactive({
  axios: applyCaseMiddleware(
    axiosInstance.create({
      baseURL: BASE_URL,
      headers: {
        "Cache-Control": "no-cache",
        Accept: "application/json",
        Pragma: "no-cache",
        Expires: "0",
        "X-User-Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    })
  ),
});

export function useAxios() {
  const { signOut } = useAuthentication();
  axiosData.axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response) {
        const { status } = error.response;

        if (status === 401) signOut();
        if (status === 403) router.replace(ROUTE_PAGE_NOT_FOUND);
      }

      // Todo: handle every error

      return Promise.reject(error);
    }
  );

  const setAdminHeader = (isAdminPrefix = false) => {
    if (isAdminPrefix) axiosData.axios.defaults.headers.admin = isAdminPrefix;
  };

  const setToken = (token: string) => {
    axiosData.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  };

  const removeToken = () => {
    axiosData.axios.defaults.headers.common.Authorization = "";
  };

  return {
    setToken,
    removeToken,
    setAdminHeader,
    ...toRefs(axiosData),
  };
}
